import axios from '@/config/httpConfig'

/**
 * 请求获取用户信息
 * @param {data} 请求参数 
 * @param {enforce} 是否强制请求最新用户信息 
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
    if (enforce || localStorage.getItem("userInformation") === null) {
        return axios.get('/user/getUserInfo', data)
    } else {
        return new Promise(function (resolve) {
            resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
        })
    }
}

//获取管理合作伙伴的权限
export function getPartnerGetPartnerPermission(data) {
    return axios.post('/partner/getPartnerPermission', data)
}

//客户下启用禁用合作伙伴
export function getPartnerPartnerEnableAccount(data) {
    return axios.post('/partner/partnerEnableAccount', data)
}

//联系人下启用禁用合作伙伴
export function getPartnerPartnerEnableContact(data) {
    return axios.post('/partner/partnerEnableContact', data)
}

//获取所有能登录的伙伴云链接
export function getPartnerQueryAllPartnerByLogin(data) {
    return axios.post('/partner/queryAllPartnerByLogin', data)
}

//保存伙伴云用户
export function getPartnerSavePartnerUser(data) {
    return axios.post('/partner/savePartnerUser', data)
}

//编辑合作伙伴用户
export function getPartnerEditPartnerUser(data) {
    return axios.post('/partner/editPartnerUser', data)
}

// 获取查找信息
export function getLookupInfo(data) {
    return axios.post('/lookup/getLookupInfo', data)
}

// 获取对象详细信息
export function getDetail(data) {
    return axios.post('/objectdetail/getDetail', data)
}

// 查找带值
export function getLookupRelatedFieldValue(data) {
    return axios.post('/lookup/getLookupRelatedFieldValue', data)
}

// 查询动态布局规则
export function getDynamicLayoutListDetail(data) {
    return axios.post('/objectdetail/getDynamicLayoutListDetail', data)
}

// 获取选项列表值
export function getSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}

// 更新视图列表记录
export function updateViewListAjax(data) {
    return axios.post('/view/list/updateViewListAjax', data)
}

// 添加或更新对象记录
export function save(data) {
    return axios.post('/objectInfo/save', data)
}

//获取对象记录权限
export function getPermissionById(data) {
    return axios.post('/objectdetail/getPermissionById', data)
}

// 获取对象权限
export function getObjectPermission(data) {
    return axios.post('objectdetail/getObjectPermission', data)
}

// 获取记录类型
export function getRecordType(data) {
    return axios.post('/batch/getRecordType', data)
}

// 删除记录
export function del(data) {
    return axios.post('/objectdetail/delete', data)
}