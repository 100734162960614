<template>
  <div class="create-edit-obj-box">
    <div class="create-edit-obj" v-loading="loading || loadingDetail">
      <info-form
        ref="infoForm"
        v-if="isEdites&&formAttr&&formAttr.length>0"
        :option-list="optionList"
        :form-attr="formAttr"
      >
      </info-form>
      <BaseForm
        v-if="!isEdites&&formAttr&&formAttr.length>0"
        ref="baseFormDetail"
        :option-list="optionList"
        :form-attr="formAttr"
      >
      </BaseForm>
    </div>
    <div class="buttons" v-if="isshowes">
      <el-button-group v-if="isEdites">
        <el-button size="mini" @click="edit">
          <!-- 编辑用户 -->
          {{ $t("label.user.modify") }}
        </el-button>
        <el-button size="mini" @click="back">
          <!-- 返回 -->
          {{ $t("label.back") }}
        </el-button>
      </el-button-group>
      <el-button-group v-else>
        <el-button
          type="primary"
          size="mini"
          @click="saveChange"
          :loading="buttonLoading"
        >
          <!-- 保存 getPartnerEditPartnerUsers-->
          {{ $t("label.save") }}
        </el-button>
        <el-button size="mini" @click="quxiao">
          <!-- 取消 -->
          {{ $t("button_partner_cancel") }}
        </el-button>
      </el-button-group>
    </div>
  </div>
</template>

<script>
/**
 * 外部用户
 */
import { InfoForm, BaseForm } from "@/components/index";
import * as CommonObjApi from "./api";
import INPUTTYPE from "@/config/enumCode/inputType";
import {
  getPartnerEditPartnerUser,
  getPartnerSavePartnerUser,
  getPartnerPartnerEnableContact,
  getPartnerGetPartnerPermission,
  getSimilarOpportunityDeleteBookmarksOppDetails,
  deleteContact,
  getApprovalHistoryList,
  Bind,
} from "./api.js";
import detailSendCard from "@/utils/robotMessage.js";
import * as SetViewApi from "./api";

export default {
  components: {
    InfoForm,
    BaseForm,
  },

  data() {
    return {
      buttonLoading: false, //保存按钮loading
      loading: false, //页面加载状态
      //判断当前是编辑还是新建
      editPage: true,
      personsName: "",
      options: [],
      pricebookdialogVisible: false,
      validRule: true,
      controlContainer: null, //依赖关系
      actionRequireds: [], // 动态布局必填字段
      actionReadonlys: [], // 动态布局只读字段
      actionShowFields: [], // 动态布局显示字段
      actionShowSections: [], // 动态布局显示部分
      //附件归档
      // dialogTabelVisible: true,
      dialogAddVisible: false,
      addFileDialogVisible: false,
      formLabelWidth: "120px",
      activeName: "first",
      enclosure: "",
      folder: "",
      checkBox: "",
      //参数
      layoutId: "",
      relatedlistId: "",
      list: [], //相关列表数组
      //大数组
      objArr: [],
      relatedList: [],
      showBtn: false,
      //tabName: this.$route.params.tabName,
      id: this.$route.params.id || this.dataId, // 记录id
      objectApi: this.$route.params.objectApi, // 对象api
      objId: "",
      prefix: this.$route.params.prefix,
      labelPosition: "top",
      editableCell: false,
      formAttr: null,
      labelWidth: 180,
      statusIcon: true,
      autoRefresh: true,
      //表格
      showKanban: false,
      tableHeight: "100",
      tableAttr: null,
      pageObj: {
        dataList: [],
      },
      noDataTip: false,
      currentIdc: "",
      type: this.$route.params.type,
      viewId: this.$route.query.viewId,
      fieldval: this.fieldValue,
      recordTypeDialogAttr: {
        // "选择记录类型"
        title: this.$i18n.t("label.recordtypeselect"),
        isShow: false,
        dialogWidth: "30%",
      },
      dialogNewAttr: {
        // 新建编辑
        title: "",
        type: "NEW",
        isShow: false,
        dialogWidth: "85%",
        id: "",
      },
      relatedArr: {},
      listObjectApi: "",
      listPrefix: "",
      recordType: "",
      recordOptions: [],
      newid: "",
      showBaseForm: false,
      dialogVisible: false, // 删除
      // formAttr: null,
      // statusIcon: true,
      // autoRefresh: false,
      //业务机会小组
      popTableVisible: false, //管理小组
      manualVisible: false, //选择价格手册
      addmanualVisible: false, //添加产品
      productsVisible: false, //编辑全部
      contactsVisible: false, //编辑业务机会联系人
      opportunityId: "",
      loadingDetail: true,
      seeMoveFlag: false, //查看更多
      //以下是报价单的添加产品及价格手册
      singleManualVisible: false,
      singleAddmanualVisible: false,
      singleProductsVisible: false,
      popNoDataTip: false, // 是否提示无更多数据
      singleNoDataTip: false,
      singlePageSize: 3,
      singlePage: 1,
      pageSize: 3,
      page: 1,
      transferOwnerDialog: false,
      transferClientOwnerDialog: false,
      optionList: {},
      ownerName: "",
      ownerId: "",
      isSendMail: false,
      checkList: [],
      ownerRaletionList: this.optional,
      optional: [
        {
          label: "转移其他人所有未处理业务机会",
          value: "otherUntreatedOpportunity",
        },
        {
          label: "转移客户所有人的已结束业务机会",
          value: "processedOpportunity",
        },
        {
          label: "转移客户所有人的全部个案",
          value: "ffe201100005108mj4GB",
        },
        {
          label: "转移客户所有人的全部发票",
          value: "ffe20181108invoice04",
        },
        {
          label: "转移客户所有人的全部报价单",
          value: "ffe201100006202iYyrO",
        },
      ],
      showSearchTable: false,
      fieldId: "",
      checked: false,
      relevantObjId: "",
      relevantPrefix: "",
      showAddCampaignMembers: false,
      //相关列表新建编辑
      relatedPrefix: "",
      data: {},
      bigArr: [],
      showDetail: false,
      operation: "DETAIL",
      headerBtn: [], // 传回父组件的审批按钮
      isSuspension: false,
      itemArr: {},
      pricebookArr: [],
      isEdit: null,
      isQuery: null,
      canEditOwner: null,
      changeDetailBoxHeight: window.screen.height - 498,
      titOffsetTop: 0,
      listObjModifyAll: false,
      listObjModify: false,
      listObjDelete: false,
      openDelay: 500,
      newObjLevel: 0, // 新建查找字段标识
      fieldProp: "",
      visibleArrow: true,
      userUrl: "",
      token: this.$CCDK.CCToken.getToken(),
      systemManager: false,
      relatedListIndex: null, // 相关列表下标
      relatedListObj: {}, //按点击钮每个菜单数据
      ownerType: "",
      listFlag: false,
      listLoading: true,
      // 短信弹框的显示隐藏
      dialogTableVisible: false,
      relationListSkeletonOption: [
        // 列表
        {
          options: {
            row: 2,
            column: 4,
            width: "14.5vw",
            height: "20px",
            backgroundColor: "#f3f2f2",
            justifyContent: "center",
            active: false,
            itemRowMargin: "6px",
            itemColumnMargin: "0px",
            firstWidth: "14.5vw",
            alignItems: "center",
          },
        },
      ],
      isSkeleton: true,
      historyData: [],
      isEdites: true,
      isshowes: true, //判断没有权限远程按钮
    };
  },
  created() {
    if (this.$route.query.edit === "false") {
      this.isEdites = false;
      this.getPartnerEditPartnerUsers();
    } else if (this.$route.query.newBuild == "true") {
      this.editPage = false;
      this.isEdites = false;
      this.getPartnerPartnerEnableContacts();
    } else {
      this.getFormAttr();
    }
  },
  methods: {
    //联系人下启用禁用合作伙伴
    async getPartnerPartnerEnableContacts() {
      this.optionList = {};
      this.formAttr = [];
      let params = {
        id: this.id,
      };
      let res = await getPartnerPartnerEnableContact(params);
      if (res.result && res.returnCode === "1") {
        res.data.forEach((group) => {
          if (group.selectionId === "adf201596551299p35Tq") {
            group.data.forEach((groupes) => {
              if (groupes.right.apiname === "isconvert") {
                this.$emit("convert", groupes.right.value);
              }
            });
          }
          // 获取详情页用户对象跳转的用户名
          let colNum = group.colNum;
          if (group.showWhenEdit) {
            this.formAttr.push({
              width: "100%",
              type: "groupTitle",
              title: group.title,
              selectionId: group.selectionId,
              groupShow: true,
              hidden: false,
            });
          }

          group.data.length > 0 &&
            group.data.forEach((line) => {
              if (
                line.left && !(Array.isArray(line.left) && line.left.length === 0) &&
                line.left.fieldType !== "blank"
              ) {
                let attr = this.formAttr.find((item) => {
                  return item.fieldId === line.left.fieldId;
                });
                if (attr === undefined) {
                  this.addFormAttr(group, colNum, line.left, undefined, "left");
                }
              } else {
                if (line.left.fieldType == "blank") {
                  this.formAttr.push({
                    width: "50%",
                    type: "noData",
                    hidden: false,
                    groupShow: true,
                    position: 'left'
                  });
                }
              }
              if (colNum !== 1) {
                if (
                  !(Array.isArray(line.right) && line.right.length === 0) &&
                  line.right.fieldType !== "blank"
                ) {
                  let attr = this.formAttr.find((item) => {
                    return item.fieldId === line.right.fieldId;
                  });
                  if (attr === undefined) {
                    this.addFormAttr(
                      group,
                      colNum,
                      line.right,
                      undefined,
                      "right"
                    );
                  }
                } else {
                  this.attrIndex += group.colNum === 1 ? 0 : 1;
                  this.formAttr.push({
                    width: "50%",
                    colNum: 2,
                    attrIndex: this.attrIndex,
                    type: "noData",
                    hidden: false,
                    groupShow: true,
                  });
                }
              }
            });
        });

        this.loadingDetail = false;
        this.$nextTick(() => {
          // 强制刷新表单
          this.$refs.infoForm && this.$refs.infoForm.filterFormData();

          // 查找带值，绑定事件
          if (
            res.data.lookupValueExpression !== undefined &&
            res.data.lookupValueExpression !== "" &&
            res.data.lookupValueExpression !== null
          ) {
            this.setLookupValue(JSON.parse(res.data.lookupValueExpression));
          }
          // 依赖关系，绑定事件
          if (res.data.controlContainer !== undefined) {
            this.controlContainer = JSON.parse(res.data.controlContainer);
            this.setControlContainer();
          }

          // 动态布局规则，绑定事件
          this.getDynamicLayoutListDetail(res.data.layoutId);

          this.formAttr.forEach((res) => {
            if (res.apiname && res.apiname == "ownerid") {
              this.$Bus.$emit("deliver-group-strategy-ownerid", res.data[0]);
            }
          });
        });
      } else {
        this.isshowes = false;
        this.$message.error(res.returnInfo);
      }
    },
    focusList() {
      document.querySelector("#focusList").scrollIntoView(true);
    },
    focusListItemone() {
      document.querySelector("#focusListItemone").scrollIntoView(true);
    },
    focusListItemtwo() {
      document.querySelector("#focusListItemtwo").scrollIntoView(true);
    },
    // 获取当前登录用户
    getCurrentUser() {
      // 获取登录用户
      CommonObjApi.getUserInfo().then((res) => {
        // 管理员可编辑
        this.systemManager = res.data.profileId === "aaa000001" ? true : false;
        if (this.systemManager) {
          this.isEdit = true;
        }
      });
    },
    addFieldOption(prop, option) {
      if (this.optionList[prop]) {
        this.optionList[prop].push(option);
      } else {
        this.optionList[prop] = [option];
      }
    },
    // 编辑标签
    editCloudcctag() {
      this.$emit("customBtn");
    },
    // 编辑详细信息
    showEditableCell() {
      this.loadingDetail = true;
      this.operation = "EDIT";
      this.getFormAttr();
    },
    // 新增查找、查找多选字段
    newSearchableField(item) {
      // 获取对象api后进行页面跳转
      CommonObjApi.getLookupInfo({
        fieldId: item.fieldId,
        objId: item.objId,
        prefix: item.prefix,
      }).then((res) => {
        let objectApi = res.data.objectApi;
        window.open(
          `#/add-relevant-obj/${item.prefix}/${objectApi}/${this.newObjLevel}?addType=option&prop=${item.prop}`
        );
      });
    },
    // 打开添加文件
    addFile(field) {
      this.$refs.addFile.tabWorkFun();
      this.fieldProp = field.prop;
      this.addFileDialogVisible = true;
    },
    // 添加文件
    addFileSubmit(workList, checkedFileList) {
      let ids = [];
      checkedFileList.forEach((file) => {
        ids.push(file.id);
      });
      this.$set(
        this.$refs.baseFormDetail.formData,
        this.$refs.addFile.fieldProp,
        ids.toString()
      );
      this.formAttr.map((attr) => {
        if (attr.prop === this.$refs.addFile.fieldProp) {
          attr.data = checkedFileList;
        }
      });
      this.addFileClose();
    },
    // 关闭添加文件
    addFileClose() {
      this.addFileDialogVisible = false;
    },
    getFormAttr() {
      this.loadingDetail = true;
      this.formAttr = [];
      this.optionList = {};

      CommonObjApi.getDetail({
        id: this.id,
        objectApi: "",
        operation: this.operation,
        recordType: this.recordType,
      })
        .then((res) => {
          if (res.result && res.returnCode === "1") {
            this.$emit("dataListes", res.data);
            this.objId = res.data.objId;
            this.objectApi = res.data.objectApi;
            this.prefix = res.data.prefix;
            // 获取prefix后才可查记录类型
            this.getRecordType();
            this.$emit("resetObjectInfo", res.data);
            this.editableCell = this.operation === "DETAIL" ? false : true;

            res.data.dataList.forEach((group) => {
              if (group.selectionId === "adf201596551299p35Tq") {
                group.data.forEach((groupes) => {
                  if (groupes.right.apiname === "isconvert") {
                    this.$emit("convert", groupes.right.value);
                  }
                });
              }
              // 获取详情页用户对象跳转的用户名
              let gropDataOne = res.data.dataList[0].data;
              this.personsName =
                gropDataOne && gropDataOne[1] ? gropDataOne[1].left.value : "";
              // 将数据传给detail父
              this.$emit("personsNameMethod", this.personsName);
              // 获取详情页用户对象跳转的用户名
              let colNum = group.colNum;
              if (group.showWhenEdit) {
                this.formAttr.push({
                  width: "100%",
                  type: "groupTitle",
                  title: group.title,
                  selectionId: group.selectionId,
                  groupShow: true,
                  hidden: false,
                });
              }

              group.data.length > 0 &&
                group.data.forEach((line) => {
                  if (
                    line.left && !(Array.isArray(line.left) && line.left.length === 0) &&
                    line.left.fieldType !== "blank"
                  ) {
                    let attr = this.formAttr.find((item) => {
                      return item.fieldId === line.left.fieldId;
                    });
                    if (attr === undefined) {
                      this.addFormAttr(
                        group,
                        colNum,
                        line.left,
                        undefined,
                        "left"
                      );
                    }
                  } else {
                    if (line.left.fieldType == "blank") {
                      this.formAttr.push({
                        width: "50%",
                        type: "noData",
                        hidden: false,
                        groupShow: true,
                        position: 'left'
                      });
                    }
                  }
                  if (colNum !== 1) {
                    if (
                      !(Array.isArray(line.right) && line.right.length === 0) &&
                      line.right.fieldType !== "blank"
                    ) {
                      let attr = this.formAttr.find((item) => {
                        return item.fieldId === line.right.fieldId;
                      });
                      if (attr === undefined) {
                        this.addFormAttr(
                          group,
                          colNum,
                          line.right,
                          undefined,
                          "right"
                        );
                      }
                    } else {
                      this.attrIndex += group.colNum === 1 ? 0 : 1;
                      this.formAttr.push({
                        width: "50%",
                        colNum: 2,
                        attrIndex: this.attrIndex,
                        type: "noData",
                        hidden: false,
                        groupShow: true,
                      });
                    }
                  }
                });
            });

            this.loadingDetail = false;
            this.$nextTick(() => {
              // 强制刷新表单
              this.$refs.infoForm && this.$refs.infoForm.filterFormData();

              // 查找带值，绑定事件
              if (
                res.data.lookupValueExpression !== undefined &&
                res.data.lookupValueExpression !== "" &&
                res.data.lookupValueExpression !== null
              ) {
                this.setLookupValue(JSON.parse(res.data.lookupValueExpression));
              }
              // 依赖关系，绑定事件
              this.controlContainer = res.data.controlContainer
                ? JSON.parse(res.data.controlContainer)
                : [];
              this.setControlContainer();
              // 动态布局规则，绑定事件
              this.getDynamicLayoutListDetail(res.data.layoutId);

              this.formAttr.forEach((res) => {
                if (res.apiname && res.apiname == "ownerid") {
                  this.$Bus.$emit(
                    "deliver-group-strategy-ownerid",
                    res.data[0]
                  );
                }
              });
            });
          } else {
            this.$message.error(res.returnInfo);
          }
        })
        .catch((err) => {
          this.isshowes = false;
          this.$message.error(err.returnInfo);
        });
    },
    // 查找带值字段
    setLookupValue(lookupValueExpression) {
      this.lookupValues = [];
      lookupValueExpression.forEach((item) => {
        this.formAttr.forEach((attr) => {
          if (attr.apiname === item.expression.split(",")[0].toLowerCase()) {
            attr.changeEvent =
              attr.changeEvent === undefined
                ? "lookupValue"
                : `${attr.changeEvent},lookupValue`;
            // 初始化规则时走规则
            if (attr.value !== undefined) {
              // 新建时必须修改
              // 编辑时，如果要带值的字段值为空，带的值必须带过去，如果值不为空，iscover为‘true’时才带值，要带值的字段有可能是查找字段，需要处理
              this.getLookupValue(item, attr.value);
            }
          }
        });
        this.lookupValues.push(item);
      });
    },
    // 查找带值
    lookupValue(selectVal, selectItem, formData) {
      this.lookupValues.forEach((lookupValue) => {
        if (
          lookupValue.expression.split(",")[0].toLowerCase() ===
          selectItem.apiname
        ) {
          this.getLookupValue(lookupValue, selectVal, formData);
        }
      });
    },
    // 查找带值
    getLookupValue(lookupRule, id, formData) {
      // id: 查找记录的id
      let fieldJson = [];
      let carryValue = "";

      fieldJson.push({
        fieldrelateid: lookupRule.id,
        id: id,
        expression: lookupRule.expression,
        iscover: lookupRule.iscover,
      });
      let param = {
        objId: this.prefix,
        fieldJson: JSON.stringify(fieldJson),
      };
      CommonObjApi.getLookupRelatedFieldValue(param).then((res) => {
        carryValue = res.data && res.data.length > 0 ? res.data[0].value : null;
        this.formAttr.forEach((attr) => {
          if (attr.fieldId === lookupRule.id) {
            // 如果是初始化时走规则，将值放到formAttr里,此处还需判断字段是否查找字段类型
            if (formData === undefined) {
              attr.value = carryValue;
            } else {
              formData[attr.apiname] = carryValue;
            }

            // 带值字段如果有字段依赖、查找、动态布局规则，执行
            let changeEvent =
              attr.changeEvent !== undefined ? attr.changeEvent.split(",") : [];
            changeEvent.forEach((eventItem) => {
              if (eventItem === "control") {
                this.control(
                  attr.value || this.$refs.baseFormDetail.formData[attr.prop],
                  attr.fieldId,
                  this.$refs.baseFormDetail.formData
                );
              } else if (eventItem === "lookupValue") {
                this.lookupValue(
                  attr.value || this.$refs.baseFormDetail.formData[attr.prop],
                  attr,
                  this.$refs.baseFormDetail.formData
                );
              } else if (eventItem === "handleAction") {
                this.handleAction();
              }
            });
          }
        });
      });
    },
    // 改变查找/查找带值选项及值
    changeSelect(row, fieldId, formData) {
      let options = [];
      let fieldVal = [];
      let optionValue = [];
      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
          optionValue.push(ele.id);
        });
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
        optionValue = row.data.id.toString();
      }

      this.formAttr.forEach((attr) => {
        if (attr.fieldId === fieldId) {
          // 防止清空时清空选项，再次编辑查找字段时显示数据Id
          if (options.length > 0) {
            this.$set(this.optionList, attr.prop, options);
          }
          if (attr.fieldType === "MR") {
            options.forEach((option) => {
              fieldVal.push(option.value);
            });
            formData[attr.apiname] = fieldVal;
          } else {
            formData[attr.apiname] = row.data === undefined ? "" : row.data.id;
          }

          // 若有查找带值，将值带入
          this.lookupValues.forEach((lookupValue) => {
            if (
              attr.apiname ===
              lookupValue.expression.split(",")[0].toLowerCase()
            ) {
              this.getLookupValue(lookupValue, optionValue, formData);
            }
          });
        }
      });
    },
    // 依赖关系
    setControlContainer() {
      // 先将被控字段选项清空,循环后有对应选项再进行赋值
      this.controlContainer.forEach((control) => {
        control.controlVals.forEach((vals) => {
          vals.dependFields.forEach((dependField) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === dependField.dependfieldid) {
                this.optionList[controlAttr.prop] = [];
              }
            });
          });
        });
      });

      this.controlContainer.forEach((control) => {
        // 控制字段
        this.formAttr.forEach((attr) => {
          if (attr.fieldId === control.controlfield) {
            this.control(
              attr.value,
              attr.fieldId,
              this.$refs.baseFormDetail.formData,
              true
            );
          }
        });
      });
    },
    // 依赖字段 ,initControl为true时是新建初始化，已经将选项值都清空，无需再次清理
    control(selectVal, fieldId, formData, initControl = false) {
      // 被控制字段集合
      let controledFieldId = [];
      let controledFieldProp = [];

      // 查找对应规则
      let control = this.controlContainer.find((control) => {
        return control.controlfield === fieldId;
      });

      if (control !== undefined) {
        // 先将被控字段选项清空,循环后有对应选项再进行赋值
        if (!initControl) {
          control.controlVals.forEach((vals) => {
            vals.dependFields.forEach((dependField) => {
              this.formAttr.forEach((controlAttr) => {
                if (controlAttr.fieldId === dependField.dependfieldid) {
                  controledFieldId.push(controlAttr.fieldId);
                  controledFieldProp.push(controlAttr.prop);
                  this.$set(this.optionList, controlAttr.prop, []);
                }
              });
            });
          });
        }

        // 查找当前值是否有控制规则
        let controlCondition = control.controlVals.find((item) => {
          return item.val === selectVal;
        });
        if (controlCondition === undefined) {
          controledFieldId.forEach((fieldId) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === fieldId) {
                // 清空被控字段值并递归
                if (controlAttr.fieldType === "Q") {
                  this.$set(formData, controlAttr.apiname, []);
                } else {
                  this.$set(formData, controlAttr.apiname, "");
                }
                this.control(formData[controlAttr.apiname], fieldId, formData);
              }
            });
          });
        } else {
          // 控制选项列表和选项值
          controlCondition.dependFields.forEach((dependField) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === dependField.dependfieldid) {
                let options = [];
                dependField.vals.forEach((element) => {
                  options.push({
                    key: element.deflangval,
                    val: element.val,
                  });
                });
                this.optionList[controlAttr.prop] = options;

                // 若当前选中值不在选项列表中，则清空
                let option = options.find((item) => {
                  return item.val === formData[controlAttr.apiname];
                });
                if (option === undefined) {
                  // 清空被控字段值并递归
                  if (controlAttr.fieldType === "Q") {
                    this.$set(formData, controlAttr.apiname, []);
                  } else {
                    this.$set(formData, controlAttr.apiname, "");
                  }
                  // 如果被控制字段选项中包含字段默认值，将依赖字段的值赋为默认值
                  if (
                    controlAttr.defaultValue &&
                    controlAttr.defaultValue !== ""
                  ) {
                    let option = options.find((item) => {
                      return item.val === controlAttr.defaultValue;
                    });
                    if (option !== undefined) {
                      this.$set(
                        formData,
                        controlAttr.apiname,
                        controlAttr.fieldType === "Q"
                          ? [controlAttr.defaultValue]
                          : controlAttr.defaultValue
                      );
                    }
                  }
                  this.control(
                    formData[controlAttr.apiname],
                    dependField.dependfieldid,
                    formData
                  );
                }
              }
            });
          });
        }

        // // 控制条件
        // control.controlVals.forEach((vals) => {
        //   if (vals.val === selectVal) {
        //   }
        // });
      }
    },
    // 动态布局规则
    getDynamicLayoutListDetail(layoutId) {
      CommonObjApi.getDynamicLayoutListDetail({
        layoutId: layoutId,
      }).then((res) => {
        // 清空动态布局规则
        this.actionRequireds = [];
        this.actionShowFields = [];
        this.actionShowSections = [];
        this.actionReadonlys = [];
        this.actionConditions = res.data;

        res.data.forEach((action) => {
          // 重置规则控制字段
          action.mainConditions.forEach((mainCondition) => {
            // 主规则
            this.handleCondition(mainCondition, false);

            this.formAttr.forEach((attr, idx) => {
              // 地址和地理定位字段动态布局返回的是父字段id
              let fieldId = attr.fieldId;
              if (attr.parentType === "AD" || attr.parentType === "LT") {
                fieldId = attr.parentId;
              }
              if (fieldId === mainCondition.fieldId) {
                let changeEvent =
                  attr.changeEvent === undefined
                    ? "handleAction"
                    : `${attr.changeEvent},handleAction`;
                this.$set(this.formAttr[idx], "changeEvent", changeEvent);
              }
            });

            // 二级规则
            mainCondition.dynamicPageConditions &&
              mainCondition.dynamicPageConditions.forEach((dynamicPage) => {
                this.handleCondition(dynamicPage, false);

                dynamicPage.twoLevelConditions.forEach((twoLevelCondition) => {
                  this.formAttr.forEach((attr, idx) => {
                    // 地址和地理定位字段动态布局返回的是父字段id
                    let fieldIdTwo = attr.fieldId;
                    if (attr.parentType === "AD" || attr.parentType === "LT") {
                      fieldIdTwo = attr.parentId;
                    }
                    if (fieldIdTwo === twoLevelCondition.fieldId) {
                      let changeEvent =
                        attr.changeEvent === undefined
                          ? "handleAction"
                          : `${attr.changeEvent},handleAction`;
                      this.$set(this.formAttr[idx], "changeEvent", changeEvent);
                    }
                  });
                });
              });
          });
        });

        // 走规则
        this.handleAction();
      });
    },
    // 重置规则控制字段
    handleCondition(condition, flag) {
      // 设置字段/部分 是否显示/必填
      // 当flag为真时，规则中只要有一个条件满足，该字段显示/必填就为真
      // 每个控制字段变化时所有规则重新走一遍
      if (flag) {
        if (condition.action_requireds) {
          condition.action_requireds.forEach((requiredField) => {
            //  如果动态布局不是只读的，判断该字段动态布局是否是必填的，需要结合动态布局是否是必填的，如果是必填的，那么就是必填，只读为false。动态布局优先级永远是大于原布局
            this.actionRequireds.forEach((item) => {
              if (item.fieldId === requiredField.field_id) {
                item.required = item.required || flag;
              }
            });
          });
        }
        if (condition.action_readonlys) {
          condition.action_readonlys.forEach((readonlysField) => {
            this.actionReadonlys.forEach((item) => {
              if (item.fieldId === readonlysField.field_id) {
                item.readonlys = item.readonlys || flag;
              }
            });
          });
        }
        if (condition.action_showFields) {
          condition.action_showFields.forEach((requiredField) => {
            this.actionShowFields.forEach((item) => {
              if (item.fieldId === requiredField.field_id) {
                item.show = item.show || flag;
              }
            });
          });
        }
        if (condition.action_showSections) {
          condition.action_showSections.forEach((requiredField) => {
            this.actionShowSections.forEach((item) => {
              if (item.selectionId === requiredField.section_id) {
                item.showSection = item.showSection || flag;
              }
            });
          });
        }
      } else {
        // 初始化需要去重
        // 字段显示与否
        if (condition.action_showFields) {
          let showFields = [];
          condition.action_showFields.forEach((requiredField) => {
            if (!showFields.includes(requiredField.field_id)) {
              showFields.push(requiredField.field_id);
              this.actionShowFields.push({
                fieldId: requiredField.field_id,
                show: flag,
              });
            }
          });
        }
        // 部分显示与否
        if (condition.action_showSections) {
          let showSections = [];
          condition.action_showSections.forEach((requiredField) => {
            if (!showSections.includes(requiredField.section_id)) {
              showSections.push(requiredField.section_id);
              this.actionShowSections.push({
                sectionName: requiredField.sectionName,
                selectionId: requiredField.section_id,
                showSection: flag,
              });
            }
          });
        }
        // 字段必填与否
        if (condition.action_requireds) {
          let requireds = [];
          condition.action_requireds.forEach((requiredField) => {
            if (!requireds.includes(requiredField.field_id)) {
              requireds.push(requiredField.field_id);
              this.actionRequireds.push({
                fieldId: requiredField.field_id,
                required: flag,
              });
            }
          });
        }
        // 只读字段

        if (condition.action_readonlys) {
          let readonlys = [];
          condition.action_readonlys.forEach((readonlysField) => {
            if (!readonlys.includes(readonlysField.field_id)) {
              readonlys.push(readonlysField.field_id);
              this.actionReadonlys.push({
                fieldId: readonlysField.field_id,
                readonlys: flag,
              });
            }
          });
        }
      }
    },
    /**
     * 判断高级条件是否成立
     * @param {Array} booleanList:条件成立结果组成的数组
     * @param {String} label:高级条件的label，表示高级条件的组合关系
     * 
     */
     isJudgment(booleanList,label){
      let isTrue = false
      if(label){
        // 去掉1 and 
        label = label.slice(5)
        // 替换and和or
        label = label.replace(/and/g,'&&')
        label = label.replace(/or/g,'||')
        // 替换每个条件的执行
        booleanList.forEach((item,index) => {
          let labelIndex = new RegExp((index+2).toString())
          label = label.replace(labelIndex,item)
        })
        isTrue = eval(label)
      }
      return isTrue
    },
    // 走规则
    handleAction() {
      this.actionConditions.forEach((action) => {
        // 循环规则控制字段
        action.mainConditions.forEach((mainCondition) => {
          let judgment = this.judgValue(
            mainCondition.fieldName,
            mainCondition.operator,
            mainCondition.value,
            mainCondition.fieldType
          );
          if (judgment) {
            this.handleCondition(mainCondition, true);
            // 二级规则 - 符合一级规则时才走二级规则
            mainCondition.dynamicPageConditions &&
              mainCondition.dynamicPageConditions.forEach((dynamicPage) => {
                // 二级条件执行
                let judgment = [];
                dynamicPage.twoLevelConditions.forEach((twoLevelCondition) => {
                  let judfment2= this.judgValue(
                      twoLevelCondition.fieldName,
                      twoLevelCondition.operator,
                      twoLevelCondition.value,
                      twoLevelCondition.fieldType
                    );
                    
                  judgment.push(judfment2)
                });
                // 执行高级条件
                if (this.isJudgment(judgment,dynamicPage.label)) {
                  this.handleCondition(dynamicPage, true);
                }
              });
          }
        });
      });

      // 根据规则设置字段显示/隐藏
      this.actionShowFields.forEach((actionShowField) => {
        this.formAttr.forEach((attr) => {
          let fieldId = attr.fieldId;
          if (attr.parentType == "AD" || attr.parentType == "LT") {
            // 地址和地理定位字段时需要对比attr上的父字段
            fieldId = attr.parentId;
          }
          if (fieldId === actionShowField.fieldId) {
            if (actionShowField.show) {
              attr.hidden = false;
            } else {
              attr.hidden = true;
            }
          }
        });
      });

      // 设置部分显示/隐藏
      this.actionShowSections.forEach((actionShowSection) => {
        if (actionShowSection.sectionName) {
          this.formAttr.forEach((attr) => {
            // 控制某部分标题显示/隐藏
            if (attr.selectionId === actionShowSection.selectionId) {
              if (actionShowSection.showSection) {
                attr.hidden = false;
                attr.groupShow = true;
              } else {
                // 部分下有必填字段时不隐藏该部分
                let requiredFields = this.formAttr.find((item) => {
                  return (
                    item.selectionId === actionShowSection.selectionId &&
                    item.required
                  );
                });
                if (requiredFields !== undefined) {
                  attr.hidden = false;
                  attr.groupShow = true;
                } else {
                  attr.hidden = true;
                  attr.groupShow = false;
                }
              }
            }
          });
        }
      });
      // 必须先走字段必填，在走字段只读
      // 根据规则设置字段必填
      this.actionRequireds.forEach((actionRequire) => {
        // 需要判断该字段动态布局是否只读,并且只读条件成立，或者原布局该字段只读
        // 该字段动态布局是否只读,并且只读条件成立
        let flag = this.actionReadonlys.find((item) => {
          return item.fieldId === actionRequire.fieldId && item.readonlys;
        });
        this.formAttr.forEach((attr) => {
          let fieldId = attr.fieldId;
          if (attr.parentType == "AD" || attr.parentType == "LT") {
            // 地址和地理定位字段时需要对比attr上的父字段
            fieldId = attr.parentId;
          }
          if (fieldId === actionRequire.fieldId) {
            // 判断该字段动态布局或者原布局是否只读
            if (flag == undefined && attr.editCopy) {
              // 只读不成立
              // 判断动态必填或者原布局必填是否成立
              if (attr.requiredCopy || actionRequire.required) {
                attr.rules = [
                  {
                    required: true,
                    message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                      attr.fieldLabel
                    }`,
                    trigger: "blur",
                    // 请输入
                  },
                ];
                // 改变只读,设置可编辑
                attr.edit = true;
              } else {
                // 该字段无规则
                // 清空必填，设置可编辑
                attr.edit = true;
                attr.rules = [];
              }
            }
          }
        });
      });
      //                 控制字段只读
      this.actionReadonlys.forEach((actionReadonly) => {
        this.formAttr.forEach((attr) => {
          let fieldId = attr.fieldId;
          if (attr.parentType == "AD" || attr.parentType == "LT") {
            // 地址和地理定位字段时需要对比attr上的父字段
            fieldId = attr.parentId;
          }
          if (fieldId === actionReadonly.fieldId) {
            // 原字段只读或者动态规则只读条件成立
            if (!attr.editCopy || actionReadonly.readonlys) {
              attr.edit = false;
              attr.rules = [];
            } else {
              // 只读不成立，判断原布局或动态布局必填
              let flag = this.actionRequireds.find((item) => {
                return item.fieldId === actionReadonly.fieldId && item.required;
              });
              if (attr.requiredCopy || flag !== undefined) {
                attr.rules = [
                  {
                    required: true,
                    message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                      attr.fieldLabel
                    }`,
                    trigger: "blur", // 请输入
                  },
                ]; // 改变只读,设置可编辑
                attr.edit = true;
              } else {
                // 该字段没有规则
                attr.edit = true;
                attr.rules = [];
              }
            }
          }
        });
      });
      this.$refs.infoForm && this.$refs.infoForm.resetRules();
    },
    // 判断值
    judgValue(field, operator, value, fieldType) {
      if (
        this.$refs.baseForm &&
        (this.$refs.baseForm.formData[field] || this.$refs.baseForm.formData[field] === 0)
      ) {
        if (fieldType === "Q") {
          //  以后组件值是数组的都要走这里
          //  多选列表选中的值
          let valueAry = this.$refs.baseForm.formData[field];
          // 主字段设置的数组
          let actionAry = value.split(",");
          // 多选列表选中值中被主字段设置的数据包含的值
          let filterAry = actionAry.filter((item) => {
            return valueAry.indexOf(item) > -1;
          });
          switch (operator) {
            case "e":
              // 等于(全包含，并且数组长度都一样)
              return (
                filterAry.length === actionAry.length &&
                filterAry.length === valueAry.length
              );
            case "n":
              // 不等于
              return !(
                filterAry.length === actionAry.length &&
                filterAry.length === valueAry.length
              );

            case "c":
              // 包含（只要有其中一个条件就成立）
              return filterAry.length > 0;
            case "k":
              // 不包含
              return filterAry.length === 0;
          }
        } else {
          // 判断字段类型
          fieldType = fieldType ? INPUTTYPE[fieldType] : fieldType;
          // 获取表单中该字段的值
          let formDateField = this.$refs.baseForm.formData[field].toString();

          if (fieldType === "date" || fieldType === "datetime") {
            // 处理日期、日期时间字段
            formDateField = new Date(formDateField).getTime().toString();
            value = new Date(value).getTime().toString();
          }

          if (fieldType === "time") {
            // 处理时间字段
            let formField = formDateField.split(":");
            let valueField = value.split(":");
            formDateField = (
              Number(formField[0] ? formField[0] : "") * 3600 +
              Number(formField[1] ? formField[1] : "") * 60 +
              Number(formField[2] ? formField[2] : "")
            ).toString();

            value = (
              Number(valueField[0] ? valueField[0] : "") * 3600 +
              Number(valueField[1] ? valueField[1] : "") * 60 +
              Number(valueField[2] ? valueField[2] : "")
            ).toString();
          }
          switch (operator) {
            case "e":
              // 等于
              return formDateField === value;
            case "n":
              // 不等于
              return formDateField !== value;
            case "l":
              // 小于
              return Number(formDateField) < Number(value);
            case "g":
              // 大于
              return Number(formDateField) > Number(value);
            case "m":
              // 小于或等于
              return Number(formDateField) <= Number(value);
            case "h":
              // 大于或等于
              return Number(formDateField) >= Number(value);
            case "c":
              // 包含
              return formDateField.includes(value);
            case "k":
              // 不包含
              return !formDateField.includes(value);
            case "s":
              // 起始字符
              return formDateField.indexOf(value) === 0;
          }
        }
      } else {
        // 如果是不包含、不等于返回true，其他false
        let flag = operator === "n" || operator === "k";
        // 如果value为空，此时operator是e（等于）返回为true
        if(operator === 'e' && !value && value!== 0){
          flag = true
        }
        return flag;
      }
    },
    // 表单添加属性
    addFormAttr(group, colNum, attr, isChildren, position, parentAttr) {
      if (position !== undefined) {
        this.$set(attr, "position", position);
      }
      // 单独存储备用原布局中的required和edit,动态布局设置规则的时候使用
      attr.requiredCopy = attr.required;
      attr.editCopy = attr.edit;
      if (attr.required) {
        attr.rules = [
          {
            required: attr.required,
            message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
              attr.fieldLabel || attr.label
            }`,
            trigger: "blur",
          },
        ];
      }
      attr.prop =
        isChildren && isChildren !== undefined ? attr.name : attr.apiname;
      if (
        parentAttr &&
        (parentAttr.fieldType == "AD" || parentAttr.fieldType == "LT")
      ) {
        // 如果父字段是地址和地理定位字段，存储父字段fieldId
        attr.parentId = parentAttr.fieldId;
        attr.parentType = parentAttr.fieldType;
      }
      if (attr.fieldType !== "AD" && attr.fieldType !== "LT") {
        this.$set(
          attr,
          "label",
          attr.fieldLabel === undefined ? attr.label : attr.fieldLabel
        );
        this.$set(
          attr,
          "type",
          INPUTTYPE[attr.fieldType === undefined ? attr.type : attr.fieldType]
        );
        if (attr.apiname === "role") {
          attr.type = "select";
        } else if (attr.apiname === "profile") {
          attr.type = "select";
        }
        // 百分数字段字数长度限制
        if (attr.fieldType === "P") {
          attr.precision = Number(attr.decimalPlaces);
          let decimal =
            attr.decimalPlaces !== undefined && attr.decimalPlaces !== null
              ? 1 / Math.pow(10, Number(attr.decimalPlaces))
              : 0;
          if (attr.fieldLength !== undefined) {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) -
                decimal,
            };
          } else {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.length - attr.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - decimal,
            };
          }
        }
        // 图片显示
        if (
          attr.fieldType === "IMG" &&
          attr.expressionType !== "url" &&
          attr.value !== undefined &&
          this.operation === "EDIT"
        ) {
          if (attr.value !== "") {
            attr.data = [
              {
                name: "",
                url: `${this.userUrl}/querysome.action?m=viewImg&fileid=${attr.value}&binding=${this.token}`,
              },
            ];
          } else {
            attr.data = [];
          }
        }
        // 评分+1
        if (attr.type === "score") {
          attr.value =
            this.operation === "EDIT" ? Number(attr.value) : Number(attr.value);
        }
        if (attr.type === "select" || attr.type === "multi-select") {
          // 记录类型可改
          let options = [];
          if (this.isEdites === false) {
            if (attr.prop === "recordtype") {
              this.recordType = attr.value;
            } else if (attr.prop === "role") {
              if (attr.roleList) {
                attr.roleList.forEach((item) => {
                  let obj = {
                    key: item.rolename,
                    val: item.roleid,
                  };
                  options.push(obj);
                });
              }
            } else if (attr.apiname === "profile") {
              attr.profileList.forEach((item) => {
                let obj = {
                  key: item.profilename,
                  val: item.id,
                };
                options.push(obj);
              });
            } else {
              attr.data &&
                attr.data.length > 0 &&
                attr.data.forEach((item) => {
                  options.push({
                    key: item.value,
                    val: item.id,
                  });
                });
            }
          } else {
            attr.data &&
              attr.data.length > 0 &&
              attr.data.forEach((item) => {
                options.push({
                  key: item.value,
                  val: item.id,
                });
              });
          }

          this.optionList[attr.prop] = options;

          if (attr.type === "multi-select") {
            // "--无--"
            attr.value =
              attr.value === this.$i18n.t("select.nullvalue") ||
              attr.value === "" ||
              attr.value === null
                ? []
                : attr.value.split(";");
          }
        } else if (
          attr.type === "remote-select" ||
          attr.type === "remote-multi-select"
        ) {
          // 查找多选字段选项设置
          if (
            attr.fieldType === "MR" &&
            attr.value.length !== 0 &&
            attr.data &&
            attr.data.length > 0 &&
            attr.data[0].value !== ""
          ) {
            let multiOptions = [];
            let labels = attr.data[0].value.split(";");
            let values = attr.data[0].id.split(";");

            for (let index = 0; index < labels.length; index++) {
              multiOptions.push({
                label: labels[index],
                value: values[index],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
            // "--无--"
            attr.value =
              attr.value === this.$i18n.t("select.nullvalue") ||
              attr.data[0].value === ""
                ? []
                : attr.value.split(";");
          } else {
            // 查找字段选项设置
            let multiOptions = [];
            // 查找字段对应的记录有对应的value时才展示
            //启用外部用户的联系人
            if (attr.apiname === "usercontact") {
              multiOptions.push({
                label: attr.usercontactName,
                value: attr.value,
              });
            } else {
              // 查找字段对应的记录有对应的value时才展示
              if (
                attr.value !== "" &&
                attr.data &&
                attr.data.length > 0 &&
                attr.data[0].value !== ""
              ) {
                multiOptions.push({
                  label: attr.data[0].value,
                  value: attr.data[0].id,
                });
              } else {
                if (
                  attr.apiname === "marketsea" &&
                  this.dialogAttr.type === "NEW" &&
                  this.isClientPoolEditStatus === false
                ) {
                  // 公海池的特殊字段，新建时才默认赋值
                  // 公海池的新建时 选中默认的当前的公海池 option
                  multiOptions.push({
                    label: this.currentClientPoolSelectObj.name,
                    value: this.currentClientPoolSelectObj.id,
                  });
                  attr.value = this.currentClientPoolSelectObj.id;
                } else {
                  attr.value = attr.fieldType === "MR" ? [] : "";
                }
              }
            }
            // 创建人和最后修改人选项
            if (
              attr.apiname === "createbyid" ||
              attr.apiname === "lastmodifybyid"
            ) {
              multiOptions.push({
                value: attr.value,
                label: attr[`${attr.apiname}Name`],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
          }
          attr.readonly = true;
        } else if (attr.type === "number") {
          if (Number(attr.decimalPlaces) !== 0) {
            attr.precision = Number(attr.decimalPlaces);
          }
          let decimal =
            attr.decimalPlaces !== undefined && attr.decimalPlaces !== null
              ? 1 / Math.pow(10, Number(attr.decimalPlaces))
              : 0;
          if (attr.fieldLength !== undefined) {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) -
                decimal,
            };
          } else {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.length - attr.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - decimal,
            };
          }
        } else if (attr.type === "checkbox") {
          if (attr.apiname === "isusing") {
            if (attr.value === "1") {
              attr.value = true;
            } else {
              attr.value = false;
            }
          } else {
            if (attr.value === "true") {
              attr.value = true;
            } else {
              attr.value = false;
            }
          }
        }
        attr.width = colNum === 1 ? "100%" : "50%";
        attr.groupTitle = group.title;
        attr.groupShow = true;
        if (
          this.operation === "DETAIL" ||
          (this.operation === "EDIT" && attr.apiname !== "cloudcctag")
        ) {
          this.formAttr.push(attr);
        }
      } else if (this.operation === "DETAIL") {
        this.$set(
          attr,
          "label",
          attr.fieldLabel === undefined ? attr.label : attr.fieldLabel
        );
        this.$set(
          attr,
          "type",
          INPUTTYPE[attr.fieldType === undefined ? attr.type : attr.fieldType]
        );
        attr.width = colNum === 1 ? "100%" : "50%";
        attr.groupTitle = group.title;
        attr.selectionId = group.selectionId;
        attr.groupShow = true;
        this.formAttr.push(attr);
      } else {
        // 地址和地理定位特殊处理
        attr.children.forEach((item) => {
          this.addFormAttr(group.title, colNum, item, true, attr);
          // 判断如果有 国家或地区(以详细地址字段名+00结尾) 字段,获取国家或地区选项
          if (item["name"].slice(item["name"].length - 2) === "00") {
            this.getCountrySelectValue(item["name"]);
          }
        });
      }
      if (attr.isContrField) {
        let changeEvent =
          attr.changeEvent === undefined
            ? "control"
            : `${attr.changeEvent},control`;
        this.$set(attr, "changeEvent", changeEvent);
      }
    },
    // 获取国家或地区选项列表值
    getCountrySelectValue(filedName) {
      CommonObjApi.getSelectValue({ fieldId: "country" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              val: item.codevalue,
              key: item.codekey,
            });
          });
          this.$set(this.optionList, filedName, options);
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // 修改记录类型
    changeRecordtype() {
      this.recordTypeDialogAttr.isShow = true;
      // 获取记录类型
    },
    // 保存记录类型
    next() {
      let datas = JSON.stringify([
        { id: this.id, recordtype: this.recordType },
      ]);
      let params = {
        objectApi: this.objectApi,
        jsonstr: datas,
        fields: "recordtype",
      };
      // if (value.toString().trim() != "") {
        CommonObjApi.updateViewListAjax(params).then((res) => {
          // 处理提示语
          let returnInfo = "";
          res.data.updateResultList.forEach((updateResult) => {
            if (updateResult.isSuccess !== "true") {
              returnInfo += this.$i18n.t("vue_label_notice_batchedit_fail", [
                updateResult.id,
                updateResult.errorMessage,
              ]);
              // `id为${updateResult.id}的数据更新失败，失败原因为${updateResult.errormessage};`
            }
          });
          if (returnInfo === "") {
            this.$message.success(
              this.$i18n.t("vue_label_notice_change_success")
            ); //"修改记录类型成功"
            this.getFormAttr();
            this.cancelRecordTypeDialog();
          } else {
            this.$message.error(returnInfo);
          }
        });
      // } else {
      //   this.$message.warning(
      //     // "提示输入不可为空"
      //     this.$i18n.t("label.value.notnull")
      //   );
      // }
    },
    // 关闭修改记录类型弹窗
    cancelRecordTypeDialog() {
      this.type = "";
      this.recordTypeDialogAttr.isShow = false;
    },
    // 取消编辑
    cancelChangeDetail() {
      this.loadingDetail = true;
      this.editableCell = false;
      this.operation = "DETAIL";
      this.getFormAttr();
    },
    // 保存编辑
    saveChange() {
      this.$refs.baseFormDetail.$refs.elForm.validate((valid) => {
        if (valid) {
          this.buttonLoading = true;
          let data = JSON.parse(
            JSON.stringify(this.$refs.baseFormDetail.formData)
          );
          delete data.undefined;
          // 使用有效的分配规则进行分配
          // "潜在客户"   "个案"
          if (
            this.tabName === this.$i18n.t("label.brief.lead") ||
            this.tabName === this.$i18n.t("label.case")
          ) {
            data.case_assiGNment = this.validRule ? "true" : "false";
          }
          for (const key in data) {
            // 修改查找多选和选项列表数据格式
            if (Array.isArray(data[key])) {
              data[key] = data[key].join(";");
              // "--无--"
            } else if (data[key] === this.$i18n.t("select.nullvalue")) {
              this.formAttr.forEach((attr) => {
                if (
                  attr.prop === key &&
                  (attr.type === "select" ||
                    attr.type === "multi-select" ||
                    attr.type === "remote-select" ||
                    attr.type === "remote-multi-select")
                ) {
                  data[key] = "";
                }
              });
            } else if (data[key] !== undefined) {
              // 所有数据均变为字符串保存
              if (data[key] !== undefined) {
                if (data[key]) {
                  data[key] = data[key].toString();
                } else {
                  data[key] = "";
                }
              }
            } else if (data[key] === undefined) {
              this.formAttr.forEach((attr) => {
                if (attr.prop === key && attr.fieldType === "P") {
                  data[key] = "";
                }
              });
            }
          }
          let params = data;
          if (this.editPage == true) {
            params.id = this.id;
          }
          getPartnerSavePartnerUser(params).then(async (res) => {
            if (res.result) {
              this.buttonLoading = false;
              if (res.returnCode == "10001") {
                this.$message.error(this.$i18n.t("front-Partner-email-tips"));
              } else {
                if (this.editPage == false) {
                  let result = await getPartnerGetPartnerPermission({
                    id: this.id,
                  });
                  if (result.result) {
                    this.id = result.data.userId;
                    this.editPage = true;
                  }
                }
                this.$message.success(this.$i18n.t("label.search.saveok"));
                this.getFormAttr();
                this.isEdites = true;
              }
            } else {
              this.$message.error(res.returnInfo);
            }
          }).catch(()=>{
            this.buttonLoading = false;
          });
        } else {
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_detail_check_the_content")
          );
        }
      });
    },
    // 保存详细信息
    saveEditableCell() {
      this.$refs.infoForm.$refs.elForm.validate((valid) => {
        if (valid) {
          let dataList = [];
          let data = this.$refs.infoForm.formData;
          delete data.undefined;

          // 使用有效的分配规则进行分配
          // if (this.tabName === "潜在客户" || this.tabName === "个案") {
          //   data.case_assiGNment = this.validRule ? 'true' : 'false';
          // }

          for (const key in data) {
            // 修改查找多选和选项列表数据格式
            if (Array.isArray(data[key])) {
              data[key] = data[key].join(";");
              // "--无--"
            } else if (data[key] === this.$i18n.t("select.nullvalue")) {
              this.formAttr.forEach((attr) => {
                if (
                  attr.prop === key &&
                  (attr.type === "select" ||
                    attr.type === "multi-select" ||
                    attr.type === "remote-select" ||
                    attr.type === "remote-multi-select")
                ) {
                  data[key] = "";
                }
              });
            } else if (data[key] !== undefined) {
              // 所有数据均变为字符串保存
              if (data[key] !== undefined) {
                if (data[key]) {
                  data[key] = data[key].toString();
                } else {
                  data[key] = "";
                }
              }
            } else if (data[key] === undefined) {
              this.formAttr.forEach((attr) => {
                if (attr.prop === key && attr.fieldType === "P") {
                  data[key] = "";
                }
              });
            }
          }
          data.id = this.id;
          dataList.push(data);
          let params = {
            objectApi: this.objectApi,
            data: JSON.stringify(dataList),
          };
          CommonObjApi.save(params).then((res) => {
            res.data.forEach((item) => {
              if (item.isSaveSuccess !== "true") {
                this.$message.error(
                  item.errormessage || this.$i18n.t("chatter.save.f")
                );
              } else {
                // "修改成功"
                this.$message.success(this.$i18n.t("message.modify.success"));
                this.getFormAttr();
                this.$parent.getBrief();
              }
            });
          });
        }
      });
    },
    // 更改所有人
    changeOwner() {
      this.ownerName = "";
      this.ownerId = "";
      this.isSendMail = false;
      if (this.objId === "account") {
        // this.getOwnerRelations();
        this.transferClientOwnerDialog = true;
        this.$nextTick(() => {
          this.$refs.transferClientOwner.getOwnerRelations();
        });
      } else {
        this.transferOwnerDialog = true;
      }
    },
    // 查找所有人
    remoteSearchOwner() {
      // 查找所有人数据写死
      this.fieldId = "ffe100000001265aWyi8";
      this.relevantObjId = "user";
      this.relevantPrefix = "005";
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    changeSelectOwner(row) {
      this.ownerName = row.data.name;
      this.ownerId = row.data.id;
      this.showSearchTable = false;
    },
    // 确认更改所有人
    saveTransferOwner() {
      this.validRule = true;
      this.operation = "DETAIL";
      this.$message.success(this.$i18n.t("vue_label_notice_change_success"));
      this.closeTransferOwnerDialog();
      // 更改所有人后重新查询记录权限
      CommonObjApi.getPermissionById({ id: this.id }).then((res) => {
        if (res.returnCode === "1" && res.result) {
          if (res.data.isQuery) {
            this.getFormAttr();
          } else {
            this.$parent.isQuery = false;
            this.$parent.fullscreenLoading = false;
          }
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    closeTransferOwnerDialog() {
      this.transferOwnerDialog = false;
      this.transferClientOwnerDialog = false;
    },
    //获取相关列表对象信息
    GetRelationList() {
      this.list = [];
      // 产品 价格手册
      if (this.prefix === "009") {
        this.list.push({
          relationName: this.$i18n.t("lable.opppdt.selPbk.title"),
          relatedlistId: "pricebook",
          relationSize: 0,
        });
      } else if (this.prefix === "006") {
        // 市场活动 影响业务机会
        // this.list.push({
        //   relationName: '影响业务机会',
        //   relatedlistId: 'influenceBusinessOpportunities',
        //   relationSize: 0
        // })
      }
      SetViewApi.GetRelationList({ recordId: this.id }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let data = [];
          data = res.data.filter(
            // "备注"
            (item) =>
              item.relatedlistType != "activityHistory" &&
              item.relatedlistType != "openActivities" &&
              item.relationName !=
                this.$i18n.t("label.weixin.group.description") &&
              item.relatedlistType != "campaign"
          );
          // ICONIMAGE未定义
          // data.forEach((val) => {
          //   // label.detailinf   详情
          //   this.$set(val, "titImg", ICONIMAGE[val.relationName + "详情"]);
          // });
          this.list = this.list.concat(data);
          this.$emit("getObjArr", this.list);
          this.$nextTick(() => {
            this.$refs.relationListObj.forEach((relationObj) => {
              relationObj.init();
            });
          });
          this.isSkeleton = false;
          //attachement备注activityHistory活动历史openActivities未处理活动  campaign 市场活动营销渠道relatedlistType
          // this.objArr = [];
          // this.relatedListIndex = 0;
          // this.getDetailRelatedItems(true);
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    offeres(data) {
      this.$emit("offeres", data);
    },
    //获取相关列表数据及按钮和字段信息
    getDetailRelatedItems(toNext, obj) {
      if (this.list && this.list.length > 0) {
        let val = null;
        if (toNext) {
          val = this.list[this.relatedListIndex];
        } else {
          // 刷新局部相关列表时通过信息定位到某个相关列表
          val = this.list.filter((item) => {
            if (item.relatedlistId === obj.id) {
              return item;
            }
          })[0];
        }

        if (val) {
          let data = {
            recordId: this.id,
            layoutId: val.layoutId ? val.layoutId : "",
            relatedlistId: val.relatedlistId ? val.relatedlistId : "",
            perPage: this.perPage,
            attachementType: val.attachementType ? val.attachementType : "",
          };
          this.listLoading = true;
          SetViewApi.GetDetailRelatedItems(data).then((res) => {
            if (res.result && res.returnCode === "1") {
              // 手动添加totalCount属性
              if(res.data && res.data.data){
                if(res.data.data[0] && res.data.data[0].norecord){
                  res.data.totalCount = 0
                }else{
                  res.data.totalCount = res.data.data.length
                }
              }else{
                res.data.totalCount = 0
              }
              // "报价单明细"
              if (
                res.data &&
                res.data.objLabel &&
                res.data.objLabel === this.$i18n.t("label.quote.detail")
              ) {
                this.$emit("offeres", res.data);
              }
              if (res.data !== null) {
                this.btnFun(res.data);
                this.$set(res.data, "listObjModify", false);
                this.$set(res.data, "listObjModify", false);
                // ICONIMAGE未定义
                // this.$set(
                //   res.data,
                //   "titImg",
                //   ICONIMAGE[res.data.objLabel + "详情"]
                // );
                // 获取相关列表每个对象权限
                if (
                  res.data.objid !== null &&
                  res.data.objid !== "" &&
                  res.data.objid
                ) {
                  CommonObjApi.getObjectPermission({ id: res.data.objid }).then(
                    (resVal) => {
                      this.$set(
                        res.data,
                        "listObjModify",
                        resVal.data && resVal.data.modify
                      );
                      this.$set(
                        res.data,
                        "listObjDelete",
                        resVal.data && resVal.data.delete
                      );
                    }
                  );
                }
                if (toNext) {
                  this.objArr.push(res.data);
                  // this.$set(this.relationData, relatedlistId, res.data)
                  this.relatedListIndex = this.relatedListIndex + 1;
                  this.relatedListIndex < this.list.length &&
                    this.getDetailRelatedItems(toNext);
                } else {
                  // 如果是删除后局部刷新相关列表，通过下边将原相关列表删除，再将刷新后 的数据加上
                  this.objArr.forEach((item, idx) => {
                    if (item.id === res.data.id) {
                      this.relatedListIndex = idx;
                    }
                  });
                  this.objArr.splice(this.relatedListIndex, 1, res.data);
                }
                this.$emit("getObjArr", this.objArr);
                if (
                  res.data.objLabel !==
                    this.$i18n.t("lable.opppdt.selPbk.title") ||
                  res.data.objLabel !== "影响业务机会" ||
                  res.data.objLabel ===
                    this.list[this.list.length - 1].relationName
                ) {
                  this.listLoading = false;
                }
              }
            } else {
              this.$message.error(res.returnInfo);
            }
          });
        }
      }
    },
    getRelationListButton(item) {
      if (item.objName === "opportunityteam") {
        //业务机会小组
        item.showButton = [
          {
            id: "",
            label: "管理小组",
          },
          {
            id: "",
            label: this.$i18n.t(
              "vue_label_commonobjects_detail_related_listview"
            ), //"列表展示"
          },
        ];
        item.moveButton = [];
      } else if (
        item.objName === "contract" ||
        item.objName === "cloudccskjh" ||
        item.objName === "quote" ||
        item.objName === "Account"
      ) {
        //合同//收款计划//报价单//客户查找合同Account//子公司Account    || item.objName === 'track'
        item.showButton = [
          {
            id: "",
            label: this.$i18n.t("label.ems.create"), //"新建",
          },
          {
            id: "",
            label: this.$i18n.t(
              "vue_label_commonobjects_detail_related_listview"
            ), //"列表展示"
          },
        ];
        item.moveButton = [];
      } else if (item.objName === "opportunitypdt") {
        //业务机会产品
        item.showButton = [
          {
            label: this.$i18n.t("lable.product.pricebooks.editall"), //编辑全部
            name: "editall",
          },
          {
            label: this.$i18n.t("lable.opppdt.selPbk.title"), //选择价格手册
            name: "selectpricebooks",
          },
          {
            label: "添加产品",
            name: "tjywjhcp",
          },
          {
            id: "",
            label: this.$i18n.t(
              "vue_label_commonobjects_detail_related_listview"
            ), //"列表展示"
          },
        ];
        item.moveButton = [];
      } else if (item.objName === "campaign") {
        //市场活动历史
        item.showButton = [
          {
            id: "",
            label: this.$i18n.t(
              "vue_label_commonobjects_detail_related_listview"
            ), //"列表展示"
          },
        ];
        item.moveButton = [];
      } else if (item.objName === "quotedetail") {
        //报价单明细
        item.showButton = [
          {
            id: "",
            label: this.$i18n.t("label.quote.detailinfo.add"), //添加报价单明细
          },
          {
            id: "",
            label: this.$i18n.t(
              "vue_label_commonobjects_detail_related_listview"
            ), //"列表展示"
          },
        ];
        item.moveButton = [];
      } else if (item.objName === "Contact" || item.objName === "competitors") {
        item.showButton = [
          {
            id: "",
            name: "New",
            label: this.$i18n.t("label.ems.create"), //"新建",
          },
          {
            id: "",
            label: this.$i18n.t(
              "vue_label_commonobjects_detail_related_listview"
            ), //"列表展示"
          },
        ];
        item.moveButton = [];
      } else if (item.objName === "tp_sys_approval_instance_his") {
        item.showButton = [
          // {
          //   id: "",
          //   label: "调回批准请求"
          // },
          // {
          //   id: "",
          //   label: "提交待审批"
          // },
        ];
        item.moveButton = [];
      } else if (item.objName === "campaignmember") {
        //市场活动成员
        item.showButton = [
          {
            id: "",
            label: "添加市场活动成员",
          },
          {
            id: "",
            label: this.$i18n.t("label.emailobject.groupemail"), //群发邮件
          },
          {
            id: "",
            label: this.$i18n.t(
              "vue_label_commonobjects_detail_related_listview"
            ), //"列表展示"
          },
          // {
          //   id: "",
          //   label: "群发短信",
          // },
        ];
        item.moveButton = [];
      } else if (
        item.objName === "Attachement" &&
        item.objLabel === this.$i18n.t("FL")
      ) {
        //文件
        //文件
        item.showButton = [
          {
            id: "",
            label: this.$i18n.t("label.file.library.selectFile2Lib"), //添加文件
          },
          {
            id: "",
            label: this.$i18n.t("chatter.down"), //"下载"
          },
          {
            id: "",
            label: this.$i18n.t(
              "vue_label_commonobjects_detail_related_listview"
            ), //"列表展示"
          },
          // {
          //   id: "",
          //   label: "附件归档"
          // },
        ];
        item.moveButton = [];
      } else if (item.objName === "tp_sys_contactrole") {
        //联系人角色
        item.showButton = [
          {
            id: "",
            label: this.$i18n.t("button_partner_workspace_manage"), //"管理",
          },
          {
            id: "",
            label: "关系网",
          },
          {
            id: "",
            label: this.$i18n.t(
              "vue_label_commonobjects_detail_related_listview"
            ), //"列表展示"
          },
        ];
        item.moveButton = [];
      } else if (item.objName === "tp_sys_opportunity_bookmarks") {
        //类似业务机会
        item.showButton = [
          {
            id: "",
            label: this.$i18n.t("label_tabpage_findz"), //"查找"
          },
          {
            id: "",
            label: this.$i18n.t(
              "vue_label_commonobjects_detail_related_listview"
            ), //"列表展示"
          },
        ];
        item.moveButton = [];
      } else {
        // item.buttons &&
        //   item.buttons.forEach((val) => {
        //     if (val.name === "New") {
        item.showButton = [
          {
            id: "",
            label: this.$i18n.t("label.ems.create"), //"新建",
          },
          {
            id: "",
            label: this.$i18n.t(
              "vue_label_commonobjects_detail_related_listview"
            ), //"列表展示"
          },
        ];
        // item.moveButton = []
      }
      //     });
      // }
    },
    // 相关列表按钮权限判断
    ifListButtonShow(label, each) {
      if (
        label === this.$i18n.t("pagecreator_page_button_edit") &&
        each.listObjModify
      ) {
        //编辑
        return true;
      } else if (
        label === this.$i18n.t("component_setup_tabs_label_delete") &&
        each.listObjDelete
      ) {
        //删除
        return true;
      } else if (
        label !== this.$i18n.t("pagecreator_page_button_edit") &&
        label !== this.$i18n.t("component_setup_tabs_label_delete") &&
        each.listObjModify
      ) {
        //编辑   删除
        return true;
      }
      return false;
    },
    getPricebook(pricebookItems) {
      this.pricebookArr = pricebookItems;
      this.$emit("getPricebook", pricebookItems);
      this.list[0].relationSize = pricebookItems.length;
    },
    //查看更多
    seeMove(val) {
      if (val.relationName) {
        this.$router.push({
          path: `/commonObjects/suspension-list/${this.$route.params.id}/${val.attachementType}`,
          query: {
            relatedlistId: val.relatedlistId,
            layoutId: val.layoutId,
          },
        });
      } else {
        this.list.forEach((item) => {
          if (item.relationName == val.objLabel) {
            this.$router.push({
              path: `/commonObjects/suspension-list/${this.$route.params.id}/${item.attachementType}`,
              query: {
                relatedlistId: item.relatedlistId,
                layoutId: item.layoutId,
              },
            });
          }
        });
      }
    },
    //点击菜单
    menuBtn(item) {
      let PageId = document.querySelector(`#${item.relatedlistId}`);
      if (this.isTag === "true") {
        this.$emit("setScroll", PageId.offsetTop - 179);
      } else {
        this.$emit("setScroll", PageId.offsetTop - 170);
      }
    },
    moveBtn(i, item) {
      let title_Id = document.querySelector(".tit88 ");
      this.titOffsetTop =
        document.documentElement.clientHeight - title_Id.offsetTop - 180;
      this.itemArr = this.$refs.relationListObj[i].objectInfo;
      if (item.relatedlistType === "approvalHistory") {
        getApprovalHistoryList({ relatedId: this.id }).then((res) => {
          this.historyData = res.data;
        });
      }
    },
    // 详情
    detailBtn(each, item) {
      if (each.objLabel !== this.$i18n.t("FL")) {
        //"文件"
        this.$router.push({
          path: `/commonObjects/detail/${item.id}/DETAIL`,
          query: {
            viewId: this.viewId,
          },
        });
      } else {
        let baseURL = this.$baseConfig.baseURL;
        let token = this.$CCDK.CCToken.getToken();
        var link = document.createElement("a");
        link.setAttribute("download", "");
        link.href = `${baseURL}/file/downloadAttachement?accessToken=${token}&id=${item.id}`;
        link.click();
      }
    },
    attachBtn(arr) {
      arr.data.forEach((val) => {
        if (val.attachChecked && val.attachChecked === true) {
          let baseURL = this.$baseConfig.baseURL;
          let token = this.$CCDK.CCToken.getToken();
          var link = document.createElement("a");
          link.setAttribute("download", "");
          link.href = `${baseURL}/file/downloadAttachement?accessToken=${token}&id=${val.id}`;
          link.click();
        }
      });
    },
    // 标签点击事件
    handleClick() {},
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    // 新建编辑弹窗关闭
    closeDialog() {
      this.dialogAttr.isShow = false;
    },
    init() {
      this.objArr = [];
      this.validRule = true;
      this.showDetail = false;
      this.isSkeleton = true;
      this.id = this.$route.params.id || this.dataId;
      this.prefix = this.id.slice(0, 3);
      // 先判断记录有没有编辑权限
      CommonObjApi.getPermissionById({ id: this.id }).then((res) => {
        // 非锁定且可编辑状态才可编辑
        this.isEdit = this.systemManager || res.data.isEdit;
        this.isQuery = res.data.isQuery || false;

        // 管理员或非锁定有更改所有人权限是显示 更改所有人按钮
        this.canEditOwner =
          this.systemManager || res.data.canEditOwner || false;
        if (this.isQuery) {
          this.showDetail = true;
        }
        this.operation = "DETAIL";
        this.GetRelationList();
        this.getFormAttr();
      });
      this.ownerRaletionList = this.optional;
      this.listFlag =
        localStorage.getItem("relatedList") === "true" ? true : false;
      this.inlineedit =
        localStorage.getItem("inlineedit") === "true" ? true : false;
      this.$parent.inlineedit = this.inlineedit;
    },
    // 获取记录类型
    getRecordType() {
      this.recordOptions = [];
      CommonObjApi.getRecordType({
        prefix: this.prefix,
      }).then((res) => {
        res.data &&
          res.data.recordTypeList &&
          res.data.recordTypeList.forEach((record) => {
            this.recordOptions.push({
              val: record.id,
              key: record.name,
            });
          });

        // 设置表单记录类型选项
        this.optionList["recordtype"] = this.recordOptions;
      });
    },
    cut() {
      this.$router.push({
        path: "/commonObjects/customer",
        query: {
          ids: this.$router.history.current.params.id,
          type: "Contact",
          content: this.objectApi,
        },
      });
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    //保存并新建
    addxinjian() {
      this.savepricebook();
      setTimeout(() => {
        this.pricebookdialogVisible = true;
      }, 500);
    },
    closeAddCampaignMembersDialog() {
      this.showAddCampaignMembers = false;
      this.$emit("refreshAll");
    },
    // 审批操作项
    openApproval(i, detailGroupId, recordId) {
      this.$refs.relationListObj.forEach((relationObj) => {
        if (relationObj.objectInfo.relatedlistType === "approvalHistory") {
          relationObj.handleHistory(i, detailGroupId, recordId);
        }
      });
    },
    // 展示审批历史的按钮--右上按钮
    showHistoryBtn(data) {
      this.headerBtn = [];
      // showReassign  重新分配
      // showApproval 进行审批
      if (data.showSubmit) {
        ////提交待审批
        this.headerBtn.push({
          id: "",
          label: this.$i18n.t("label.submitforapproval"),
          hisId: "0",
        });
      }
      if (data.showApproval) {
        //进行审批
        this.headerBtn.push({
          id: "",
          label: this.$i18n.t("label.approveorrefuse"),
          hisId: "2",
        });
      }
      if (data.showReassign) {
        //重新分配
        this.headerBtn.push({
          id: "",
          label: this.$i18n.t("label.redistribution"),
          hisId: "3",
        });
      }
      if (data.showRecall) {
        //调回批准请求
        this.headerBtn.push({
          id: "",
          label: this.$i18n.t("label.manageAll.sta4"),
          hisId: "1",
        });
      }
      this.$emit("topHistoryBtn", this.headerBtn);
    },
    // 编辑
    everyEdit(each, item) {
      CommonObjApi.getPermissionById({ id: this.id }).then((res) => {
        if (res.data.isEdit) {
          this.dialogNewAttr.id = item.id;
          this.$nextTick(() => {
            this.$refs.createEditObj.edit();
          });
          this.listObjectApi = each.objName;
          this.listPrefix = each.prefix;
        } else {
          this.$message.warning(
            this.$i18n.t("label.commonobjects.Nopermission")
          );
        }
      });
    },
    // 新增/修改保存
    save() {
      this.$emit("refreshAll");
      this.$message.success(this.$i18n.t("label.search.saveok")); //保存成功
    },
    // 确认删除数据
    everyDelet(obj, name, id, item1) {
      this.DeletObj = obj;
      this.DeletName = name;
      this.DeletId = id;
      this.DeletItem1 = item1;
      this.dialogVisible = true;
    },
    // 确认删除数据
    confirmDel() {
      CommonObjApi.getPermissionById({ id: this.id }).then((res) => {
        if (res.data.isDelete) {
          //如果是联系人走单独删除接口
          if (this.DeletName === "tp_sys_contactrole") {
            deleteContact({ id: this.DeletId, opportunityId: this.id })
              .then(() => {
                this.dialogVisible = false;
                this.$message({
                  showClose: true,
                  message: this.$i18n.t("label.ems.delete.success"),
                  type: "success",
                }); //删除成功
                this.$emit("refreshAll");
              })
              .catch((err) => {
                this.$message.error(err.returnInfo);
              });
          } else if (this.DeletName === "tp_sys_opportunity_bookmarks") {
            getSimilarOpportunityDeleteBookmarksOppDetails({
              id: this.DeletItem1.bookmarksid,
            })
              .then(() => {
                this.dialogVisible = false;
                this.$message({
                  showClose: true,
                  message: this.$i18n.t("label.ems.delete.success"),
                  type: "success",
                }); //删除成功
                this.$emit("refreshAll");
              })
              .catch((err) => {
                this.$message.error(err.returnInfo);
              });
          } else {
            CommonObjApi.del({ id: this.DeletId, objectApi: this.DeletName })
              .then(() => {
                this.dialogVisible = false;
                this.$message({
                  showClose: true,
                  message: this.$i18n.t("label.ems.delete.success"),
                  type: "success",
                }); //删除成功
                this.$emit("refreshAll");
              })
              .catch((err) => {
                this.$message.error(err.returnInfo);
              });
          }
        } else {
          this.$message.warning(
            this.$i18n.t("vue_label_notice_nopermission_delete")
          );
        }
      });
    },
    //管理小组 关闭弹窗
    popTableClose(n) {
      if (n) {
        this.$emit("refreshAll");
        this.popTableVisible = false;
      } else {
        this.popTableVisible = false;
      }
    },
    //选择价格手册 关闭弹窗
    manualClose() {
      this.manualVisible = false;
    },
    //添加产品 关闭弹窗
    addmanualClose() {
      this.addmanualVisible = false;
    },
    //是否刷新相关列表
    successFun(n) {
      if (n) {
        this.$emit("refreshAll");
        this.productsVisible = false;
      } else {
        this.productsVisible = false;
      }
    },
    //编辑业务机会联系人
    contactsClose(n) {
      if (n) {
        this.$emit("refreshAll");
        this.contactsVisible = false;
      } else {
        this.contactsVisible = false;
      }
    },
    //业务机会产品选择价格手册 下一步
    addmanualChange() {
      this.addmanualVisible = true;
      this.$refs.popUpteam.searchProducts();
      this.$refs.popUpteam.addmanualInit();
    },
    //添加产品 下一步
    productsChange() {
      this.productsVisible = true;
    },
    //添加文件
    addWorkClose(n) {
      if (n) {
        this.$emit("refreshAll");
        this.dialogAddVisible = false;
      } else {
        this.dialogAddVisible = false;
      }
    },
    addWorkSubmit(workList, checkedFileList) {
      let data = [];

      checkedFileList.map((item) => {
        let dataItem = {};
        dataItem.recordid = this.id;
        dataItem.name = item.name.split(".")[0];
        dataItem.type = item.type;
        dataItem.fileContentId = item.fileContentId;
        dataItem.fileinfoid = item.id;
        data.push(dataItem);
      });
      Bind(JSON.stringify(data)).then((res) => {
        let self = this;
        if (res.result === true) {
          //群策卡片
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].cardValue) {
              (function (i) {
                setTimeout(async () => {
                  await detailSendCard(res.data[i].cardValue.groupId, {
                    title: self.$i18n.t("vue_label_newfile"),
                    recordId: res.data[i].cardValue.id,
                    type: "NORMAL_CARD",
                    body: res.data[i].cardValue.fieldsList,
                    changer: localStorage.getItem("username"),
                  });
                }, 500 * i);
              })(i);
            }
          }
          this.$message({
            showClose: true,
            type: "success",
            message: "添加成功",
          });
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: "添加失败",
          });
        }
      });
      this.addWorkClose(true);
    },
    //报价单选择价格手册 下一步
    singleAddmanualChange() {
      this.singleAddmanualVisible = true;
      this.$refs.quotation.singleSearchProducts();
      this.$refs.quotation.addmanualInit();
    },
    //选择价格手册 关闭弹窗
    singleManualClose() {
      this.singleManualVisible = false;
    },
    //添加产品 关闭弹窗
    singleAddmanualClose() {
      this.singleAddmanualVisible = false;
    },
    //是否刷新相关列表
    singleSuccessFun(n) {
      if (n) {
        this.$emit("refreshAll");
        this.singleProductsVisible = false;
      } else {
        this.singleProductsVisible = false;
      }
    },
    //添加产品 下一步
    singleProductsChange() {
      this.singleProductsVisible = true;
    },
    // 点击确认隐藏短信弹框
    confirmationMessage() {
      this.dialogTableVisible = false;
    },
    // 取消隐藏短信弹框
    cancelMssage() {
      this.dialogTableVisible = false;
    },
    routers() {
      if (this.objectApi === "Account") {
        this.$router.push({
          path: "/commonObjects/customer",
          query: {
            ids: this.id,
            type: "Account",
            content: this.objectApi,
          },
        });
      } else if (this.objectApi === "Contact") {
        this.$router.push({
          path: "/commonObjects/customer",
          query: {
            ids: this.id,
            type: "Contact",
            content: this.objectApi,
          },
        });
      } else if (this.objectApi === "campaign") {
        // this.isCustomer = true
        // this.content = '市场层级结构'
        this.$router.push({
          path: "/commonObjects/customer",
          query: {
            ids: this.id,
            type: "Campaign",
            content: this.objectApi,
          },
        });
      } else {
        this.isCustomer = false;
      }
    },
    edit() {
      this.getPartnerEditPartnerUsers();

      // if(this.$route.query.newBuild == "true"){
      //   this.getPartnerPartnerEnableContacts()
      // }else{
      // this.getPartnerEditPartnerUsers();
      // }
      this.isEdites = false;
    },
    back() {
      this.$router.go(-1);
    },
    quxiao() {
      this.isEdites = true;
    },
    async getPartnerEditPartnerUsers() {
      this.loading = true;
      this.optionList = {};
      this.formAttr = [];
      let params = {
        id: this.id,
      };
      let res = await getPartnerEditPartnerUser(params);
      if (res.result && res.returnCode === "1") {
        this.loading = false;
        res.data.forEach((group) => {
          if (group.selectionId === "adf201596551299p35Tq") {
            group.data.forEach((groupes) => {
              if (groupes.right.apiname === "isconvert") {
                this.$emit("convert", groupes.right.value);
              }
            });
          }
          // 获取详情页用户对象跳转的用户名
          let colNum = group.colNum;
          if (group.showWhenEdit) {
            this.formAttr.push({
              width: "100%",
              type: "groupTitle",
              title: group.title,
              selectionId: group.selectionId,
              groupShow: true,
              hidden: false,
            });
          }

          group.data.length > 0 &&
            group.data.forEach((line) => {
              if (
                !(Array.isArray(line.left) && line.left.length === 0) &&
                line.left.fieldType !== "blank"
              ) {
                let attr = this.formAttr.find((item) => {
                  return item.fieldId === line.left.fieldId;
                });
                if (attr === undefined) {
                  this.addFormAttr(group, colNum, line.left, undefined, "left");
                }
              } else {
                this.formAttr.push({
                  width: "50%",
                  type: "noData",
                  hidden: false,
                  groupShow: true,
                });
              }
              if (colNum !== 1) {
                if (
                  !(Array.isArray(line.right) && line.right.length === 0) &&
                  line.right.fieldType !== "blank"
                ) {
                  let attr = this.formAttr.find((item) => {
                    return item.fieldId === line.right.fieldId;
                  });
                  if (attr === undefined) {
                    this.addFormAttr(
                      group,
                      colNum,
                      line.right,
                      undefined,
                      "right"
                    );
                  }
                } else {
                  this.attrIndex += group.colNum === 1 ? 0 : 1;
                  this.formAttr.push({
                    width: "50%",
                    colNum: 2,
                    attrIndex: this.attrIndex,
                    type: "noData",
                    hidden: false,
                    groupShow: true,
                  });
                }
              }
            });
        });
        this.loadingDetail = false;
        this.$nextTick(() => {
          // 强制刷新表单
          this.$refs.infoForm && this.$refs.infoForm.filterFormData();

          // 查找带值，绑定事件
          if (
            res.data.lookupValueExpression !== undefined &&
            res.data.lookupValueExpression !== "" &&
            res.data.lookupValueExpression !== null
          ) {
            this.setLookupValue(JSON.parse(res.data.lookupValueExpression));
          }
          // 依赖关系，绑定事件
          if (res.data.controlContainer !== undefined) {
            this.controlContainer = JSON.parse(res.data.controlContainer);
            this.setControlContainer();
          }

          // 动态布局规则，绑定事件
          this.getDynamicLayoutListDetail(res.data.layoutId);

          this.formAttr.forEach((res) => {
            if (res.apiname && res.apiname == "ownerid") {
              this.$Bus.$emit("deliver-group-strategy-ownerid", res.data[0]);
            }
          });
        });
      } else {
        this.isshowes = false;
        this.$message.error(res.returnInfo);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-edit-obj-box {
  height: 92%;
  overflow: auto;
}
.create-edit-obj {
  padding: 0px 20px 0px 20px;
  margin-bottom: 20px;
  background: white !important;
  min-height: 100%;
}
::v-deep .buttons {
  text-align: center;
  position: fixed;
  bottom: 50px;
  width: 100%;
  .el-button-group {
    .el-button {
      margin-right: 20px !important;
    }
  }
}
</style>
